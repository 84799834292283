.EmailListBg {
	background-color: #006071;
	padding: calc(100vw * 30/390) 0px 10px 00px;
	margin-top:  calc(100vw * 50/390);
}

.EmailListContainer {
	width: calc(100vw * 250/390);
	max-width: 325px;
	font-size: calc(100vw * 18/390);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px auto;
}

.EmailListContainer>div {
	margin-bottom: calc(100vw * 17/390);
	color: white;
}

.EmailListContainer>input {
	width: 100%;
	height: calc(100vw * 40/390);
	max-height: 50px;
	border-radius: 8px;
	border: 1px solid #4FAEBE;
	text-align: center;
	font-size: calc(100vw * 14/390);
	font-weight: 600;
	margin-bottom: calc(100vw * 12/390)
}

.EmailListContainer>button {
	background-color: #4FAEBE;
	color: white;
	width: calc(100vw * 124.5/390);
	max-width: 144.5px;
	height: calc(100vw * 40/390);
	max-height: 45px;
	border: 0px;
	border-radius: 8px;
	margin-bottom:calc(100vw * 40/390);
	font-size: calc(100vw * 14/390);
	font-weight: 700;
	cursor: pointer;
}

.FooterCopyWrite {
	font-size: calc(100vw * 14/390);
	color: white;
	font-weight: 400;
	margin: 0px;
}

@media (min-width: 470px ) {
	.EmailListBg {
		padding: 36px 0px 10px 0px;
		margin-top: 60px;
	}
	
	.EmailListContainer>div {
		margin-bottom: 20px;
	}
	
	.EmailListContainer>input {
		font-size: 17px;
		margin-bottom: 14.5px;
	}
	
	.EmailListContainer {
		font-size: 22px;
	}

	.FooterCopyWrite {
		font-size: 17px;
	}
	.EmailListContainer>button {
		margin-bottom: 48px;
		font-size: 17px;
	}
}