.LandingHeader{
	padding: 15px 0px;
	border-bottom: solid 0.5px #6B6767;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.LandingHeaderContainer{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90vw;
}
.LandingLogo{
	background-image: url("../../Assets/LandingPage/Ethraa.svg");
	width: calc(100vw * 113.99/390);
	height: calc(100vw * 22/390);
	max-width: 171.33px;
	max-height: 33px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.LandingHeaderActions{
	width: calc(100vw * 222.44/390);
	max-width: 285px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 390px) {
	.LandingHeader{
		padding: calc(100vw * 15/390) 0px;
	}
}

