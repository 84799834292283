.CountdownContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100vw * 250/390);
	max-width: 325px;
	text-align: center;
	margin: 0px auto;
}
.CountdownTitle{
	font-size: calc(100vw * 18/390);
	font-weight: 500;
	color: #4FAEBE;
}
.CountdownSectionsContainer{
	color: #006071;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 7px 0px;
	width: 100%;
}
.CountdownSection{
	width: calc(100vw * 50/390);
	max-width: 70px;
	font-size: calc(100vw * 12/390);
	font-weight: 400;
	color: #6B6767;
	display: flex;
	flex-direction: column;
}
.CounterDivider, .CountdownNumber{
	font-weight: 700px;
	font-size: calc(100vw * 27/390);
	color: #006071;
	margin-bottom: 7px;
}

@media (min-width: 470px ) {
	.CountdownTitle{
		font-size: 23px;
	}
	.CountdownSection{
		width: 70px;
		font-size: 17px;
	}
	.CounterDivider, .CountdownNumber{
		font-size: 32px;
	}
}