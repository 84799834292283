*{
  font-family: "Noto Sans Arabic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:"slnt" 0;
  line-height: 140%;
  direction: rtl;
}

.En *{
  direction: ltr;
}

body, html{
  padding: 0px;
  margin: 0px;
  text-align: center;
  background-color: #E7FCFF;
  color: black;
  font-weight: 400;
}

.Blue{
  color: #4FAEBE;
}
