.ToggleContainer{
	width: calc(100vw * 88/390);
	height: calc(100vw * 31/390);
	max-height: 45px;
	max-width: 120px;
	border: 1px #2591A4 solid;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	direction: ltr;
}
.Toggle{
	width: calc(100vw * 39.6/390);
	height: calc(100vw * 25.67/390);
	max-width: 54px;
	max-height: 35px;
	color: #2591A4;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 24px;
	font-family: Arial, Helvetica, sans-serif;
}
.ActiveToggle{
	background-color: #2591A4;;
	color: white;
}

@media (max-width: 670px) {
	.Toggle{
		font-size: calc(100vw * 14/390);
	}
}