.LandingPageContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 35px 0px;
}

.ProfilesImage {
	background-image: url("../Assets/LandingPage/ProfilesImage.png");
	width: calc(100vw * 112.5/390);
	height: calc(100vw * 40/390);
	max-height: 48px;
	max-width: 135px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-bottom: 6px;
}

.LandingSlogan,
.LandingSlogan>* {
	font-size: calc(100vw * 33/390);
	width: calc(100vw * 360/390);
	max-width: 800px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 18px;
}

.LandingDescription {
	width: calc(100vw * 360/390);
	max-width: 760px;
	font-size: calc(100vw * 16/390);
	margin-bottom: 35px;
}

.LandingPrimaryBtn {
	width: calc(100vw * 160/390);
	height: calc(100vw * 50/390);
	max-width: 256px;
	max-height: 65px;

	background-image: linear-gradient(to bottom, #C7A7F2 25%, #7E3FCA 50%, #ED4B64 75%, #FF9D4B 100%);
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: calc(100vw * 18/390);
	font-weight: 800;
	color: white;
	border: 0.5px solid black;
	box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.25);
	margin-bottom: calc(100vw * 6/390);
	cursor: pointer;
}

.LandingPrimaryBtnContainer{
	width: calc(100vw * 120/390);
	height: calc(100vw * 50/390);
	max-width: 140px;
	max-height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.InstagramIcon {
	background-image: url("../Assets/LandingPage/InstagramWhite.svg");
	width: calc(100vw * 22.71/390);
	height: calc(100vw * 22.71/390);
	max-width: 25px;
	max-height: 25px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.LandingAppUiEn {
	background-image: url("../Assets/LandingPage/AppUIsEn.png");
	width: 100vw;
	max-width: 630.33px;
	height: calc(100vw * 330.76/390);
	max-height: 534.97px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.LandingAppUiAr {
	background-image: url("../Assets/LandingPage/AppUIsAr.png");
	width: 100vw;
	max-width: 630.33px;
	height: calc(100vw * 330.76/390);
	max-height: 534.97px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

@media (min-width: 830px) {
	.LandingSlogan,
	.LandingSlogan>* {
		font-size: 70px
	}
}

@media (min-width: 660px) {
	.LandingDescription {
		font-size: 27px;
		margin-bottom: 35px;
	}
	.LandingPageContent {
		margin: 35px 0px;
	}
}

@media (min-width: 455px) {
	.LandingPrimaryBtn {
		font-size: 21px;
		margin-bottom: 6px
	}
}