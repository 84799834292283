.LandingActionBtn {
	width: calc(100vw * 124.44/390);
	height: calc(100vw * 40/390);
	max-width: 140px;
	max-height: 45px;
	border-radius: 7px;
	background-color: black;
	font-size: 18px;
	font-weight: 600;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

@media (max-width: 440px) {
	.LandingActionBtn {
		font-size: calc(100vw * 16/390);
	}
}